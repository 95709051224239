import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import DownloadApp from '../components/DownloadApp';
import ImproveYourLife from '../components/ImproveYourLife';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO title="Sleep Better" />

    <div>
      <div className="section image-bg-section">
        <div className="container">
          <div className="content-width-medium">
            <h6 className="subheading">Sleep Better</h6>
            <h1 className="no-bottom-space center-mobile">Enjoy restful revitalizing sleep every night</h1><Link to="/pricing/" className="button margin-top w-inline-block">
              <div>Get started</div>
            </Link>
          </div>
        </div>
        <div style={{backgroundImage: 'url("/images/5ddc2806d31f0f55e471ea4d_awake_t20_3JLNyR.jpg")'}} className="image-background bg-image-1" />
        <div className="background-scrim-left" />
      </div>
      <div className="section">
        <div className="container">
          <div className="w-richtext">
            <p>Many people have trouble getting to sleep and staying asleep long enough to get the quantity and quality of sleep they need for optimum health and well-being.</p>
            <p>The WiseGuide App is designed to help you enjoy a wonderfully peaceful night’s sleep and wake up feeling better than ever. You don’t have to have insomnia to benefit from our app. It promotes pleasant, productive dreams and peace of mind.</p>
            <p>Start a FREE 7-day trial to experience how The WiseGuide App can help you enjoy restful and revitalizing sleep. Just listen.</p>
          </div>
        </div>
      </div>
    </div>

    <ImproveYourLife />

    <DownloadApp />
  </Layout>
);
